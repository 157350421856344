:root {
  --animate-delay: 0.1s;
}

#root {
  @apply flex flex-col h-full;
}

html,
body {
  @apply h-full;
}

body {
  @apply bg-gray-100 font-main;
}

.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter-active.fade-enter-active {
  opacity: 1;
  transition: all 0.3s ease-in;
}

.modal-overlay {
  @apply opacity-0 transition-opacity fixed top-0 left-0 right-0 bottom-0 bg-black bg-opacity-50 h-full;
}

.ReactModal__Overlay--after-open {
  @apply opacity-100;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.modal {
  @apply transform m-auto overflow-y-hidden top-1/4 relative w-11/12 sm:w-10/12 md:w-8/12 xl:w-7/12 bg-white p-4 rounded-md;
}

.dropdown {
  @apply relative;
}

.dropdown__control {
  @apply flex items-center justify-between border-2 font-bold bg-white rounded-sm text-lg border-gray-300 p-3 outline-none focus:border-primary transition-colors;
}

.dropdown__placeholder {
  @apply text-gray-300;
}

.dropdown__placeholder.is-selected {
  @apply text-black text-lg;
}

.dropdown__menu {
  @apply absolute top-full shadow-xl left-0 w-full border-2 border-t-0 bg-white text-lg border-gray-300;
}

.dropdown__menu {
  @apply divide-gray-300 divide-y-2;
}

.dropdown__menu div {
  @apply p-2 text-base;
}

.dropdown__menu div.is-selected {
  @apply bg-primary bg-opacity-80 border-0 text-white font-bold;
}

.dropdown__arrow {
  @apply text-2xl text-gray-700;
}

.dropdown__arrow_open {
  @apply transform rotate-180 transition-transform;
}

.menu-item {
  height: 100%;
  min-height: 120px;
}

@screen sm {
  .menu-item {
    min-height: 150px;
  }
}
